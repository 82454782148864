import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'

const PracticesPage = () => {
  return (
    <Layout pageTitle="Practices">
      <p>Series of attempts　一連の試み</p>
      <h2>OpenStudioApplication</h2>
      <Link to="/practices/os01">
        OpenStudioApplicationについて
      </Link>
    </Layout>
  )
}
export default PracticesPage
